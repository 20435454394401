import React from 'react';
import Icon from '../../atoms/icon';
import ContenutoHtmlInner from '../../molecules/helpers-contenuto-html/ContenutoHtmlInner';
const PhoneBox = ({ singleBox, key }) => {
  return (
    <div
      key={key}
      className='phone-box'
      style={{
        backgroundColor: `var(--${singleBox?.content?.boxColor?.value[0]})`,
      }}
    >
      {singleBox?.content?.iconSvgId?.value && (
        <div className='phone-box__icon'>
          <div className='phone-box__pallocchio'></div>
          <Icon
            icon={singleBox?.content?.iconSvgId?.value}
            thickness={
              singleBox?.content?.iconSvgId?.content?.iconThicknessBox?.value[0]
            }
          ></Icon>
        </div>
      )}
      <div className='phone-box__content'>
        {singleBox?.content?.boxTitle?.value && (
          <span className='phone-box__title'>
            {singleBox?.content?.boxTitle?.value}
          </span>
        )}
        {singleBox?.content?.html?.value && (
          <div className='phone-box__html box-editorial'>
            <ContenutoHtmlInner rawContent={singleBox?.content?.html?.value} />
          </div>
        )}
        {singleBox?.content?.phoneNumber?.value && (
          <a
            href={`tel:${singleBox?.content?.phoneNumber?.value}`}
            className=' phone-box__tel'
          >
            {singleBox?.content?.phoneNumber?.value}
          </a>
        )}
      </div>
    </div>
  );
};

export default PhoneBox;
