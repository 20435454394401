import React from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import { Wrapper } from '../../utils/components';
import { getCommonOrganismData } from '../../utils/functions';
import useWithMobileResizeListener from '../../utils/hooks/useWithMobileResizeListener';
import { PrevArrow, NextArrow } from '../../molecules/arrows/arrows';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import Title from '../../molecules/title/title';
import PhoneBox from './PhoneBox';
import './PhoneBoxes.scss';
import withPreview from '../../utils/with-preview';

const EMPTY_OBJECT = [];
const PhoneBoxes = ({ data }) => {
  const isMobile = useWithMobileResizeListener(768);
  const isBelowSmall = useWithMobileResizeListener(576);
  const {
    anchorLabel,
    backgroundColor,
    htmlTagWrapper,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);
  const box = data?.box || EMPTY_OBJECT;
  const hideSliderOver = data?.hideSliderOver?.value;
  const hideSliderUnder = data?.hideSliderUnder?.value;
  const disableArrows = data?.disableArrows?.value;

  const sliderSettings = {
    infinite: false, // If is set to true, if elements < sideToShow it generetes clone tile
    dots: true,
    speed: 500,
    arrows: !disableArrows,
    slidesToShow: 3,
    slidesToScroll: 3,
    slide: '',
    prevArrow: !disableArrows && <PrevArrow />,
    nextArrow: !disableArrows && <NextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: '0px' }}> {dots} </ul>
      </div>
    ),
    customPaging: () => <div className='slick-dots__custom-dot'></div>,
  };

  const boxMapped = box.map((singleBox, index) => {
    return <PhoneBox singleBox={singleBox} key={index} />;
  });

  const render = (condition) =>
    condition ? (
      <ul
        className={`phone-boxes__list items-${
          box.length <= 3 ? box.length : '4'
        }`}
      >
        {boxMapped.map((box, i) => (
          <li className='phone-boxes__list-item' key={i}>
            {box}
          </li>
        ))}
      </ul>
    ) : (
      <Slider className='phone-boxes__slider' {...sliderSettings}>
        {boxMapped}
      </Slider>
    );

  const renderDesktop = () => render(hideSliderOver || box.length <= 3);
  const renderMobile = () =>
    render(
      (() => {
        if (hideSliderUnder) return true;
        if (isBelowSmall) {
          return box.length === 1;
        }
        return box.length <= 2;
      })()
    );
  return (
    <Wrapper
      data-module-name='phone-boxes'
      data-module-code='C-103'
      tag={htmlTagWrapper}
      style={{ '--bg': `var(--${backgroundColor})` }}
      className={classNames(`phone-boxes wrapper ${marginTop}`)}
    >
      <AnchorHooking id={anchorLabel} />
      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
        >
          {title}
        </Title>
      )}
      {isMobile ? renderMobile() : renderDesktop()}
    </Wrapper>
  );
};

export default withPreview(PhoneBoxes);
